/**
 * @ngdoc service
 * @name BpmnEventNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description A service for creating BPMN EVENT nodes (start/end).
 *
 * ## Notes
 *
 * ### Model Usage
 *  { key: 101, category: "event", text: "Start",   eventType: 1, eventDimension: 1, item: "start"},
 *
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 * ###API
 * * getNode -  Get an event node defined by the (optional) options
 * * getPaletteNode - Get a event palette node defined by the (optional) options
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/541a9cd54770a525c26af6d64ee8d4d8625af645/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.event.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnEventNodeService', bpmnEventNodeService);

bpmnEventNodeService.$inject = [
  'goService',
  'BpmnPartsFactory',
  'BPMN_CONSTANTS',
  'BpmnCommonService'
];

function bpmnEventNodeService(
  goService: GoJS,
  BpmnPartsFactory,
  BPMN_CONSTANTS,
  BpmnCommonService
) {
  const $GO = goService.GraphObject.make;

  //--------------------------------------------DEFAULTS------------------------------------------

  const defaults = {
    EventNodeSize: BPMN_CONSTANTS.EventNodeSize,
    EventNodeInnerSize: BPMN_CONSTANTS.EventNodeInnerSize /* EventNode -6*/,
    EventNodeSymbolSize: 22 /*EventNodeInnerSize -14*/,
    EventSymbolLightFill: BPMN_CONSTANTS.EventNodeFill,
    EventSymbolDarkFill: BPMN_CONSTANTS.TerminateInnerCircle,
    EventDimensionStrokeColor: BPMN_CONSTANTS.EventStartBorder,
    EventDimensionStrokeEndColor: BPMN_CONSTANTS.EventEndBorder,
    EventDimensionInnerStrokeColor: BPMN_CONSTANTS.TerminateInnerCircle,
    EventNodeStrokeWidth: BPMN_CONSTANTS.EventNodeStrokeWidth,
    EventNodePaletteStrokeWidth: BPMN_CONSTANTS.EventNodePaletteStrokeWidth,
    EventNodeStrokeWidthIsEnd: BPMN_CONSTANTS.EventNodeStrokeWidth,
    EventNodePaletteFill: BPMN_CONSTANTS.EventNodePaletteFill
  };

  const service = {
    getNode: getNode,
    getPaletteNode: getPaletteNode
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  function getNode(options) {
    angular.extend(defaults, options); //override defaults, if options supplied

    return getEventNode(BPMN_CONSTANTS.NoScale, true);
  }

  function getPaletteNode(options) {
    angular.extend(defaults, options); //override defaults, if options supplied

    return makePaletteNode();
  }

  /// PRIVATE ///////////////////////////////////////////////////////////////////////

  function makePaletteNode(scale?, useAdornment?) {
    const $GO = goService.GraphObject.make;

    return $GO(
      goService.Part,
      goService.Panel.Vertical,
      { width: BPMN_CONSTANTS.palette.innerWidth, selectionAdorned: false },
      $GO(
        goService.Picture,
        {
          name: 'Icon',
          desiredSize: !BpmnCommonService.isInternetExplorer
            ? new goService.Size(
                BPMN_CONSTANTS.palette.icon.innerWidth,
                BPMN_CONSTANTS.palette.icon.innerHeight
              )
            : new goService.Size(
                BPMN_CONSTANTS.palette.iconIE.event.innerWidth,
                BPMN_CONSTANTS.palette.iconIE.event.innerHeight
              ),
          scale: !BpmnCommonService.isInternetExplorer
            ? 1
            : BPMN_CONSTANTS.palette.iconIE.event.scale
        },
        new goService.Binding('source', 'item', (item) => {
          switch (item.toLowerCase()) {
            case 'start':
              return ASSETS_PATH + '/start.svg';
            case 'end':
              return ASSETS_PATH + '/end.svg';
            default:
              throw new Error('Unsupported item type ' + item);
          }
        })
      ),

      BpmnPartsFactory.getTextBlock(
        'text',
        undefined,
        BPMN_CONSTANTS.PaletteTextColour,
        'move'
      )
    );
  }

  //---------------------------------------------HELPERS------------------------------------------
  function nodeEventDimensionStrokeColorConverter(s) {
    if (s === 8) return defaults.EventDimensionStrokeEndColor;
    if (s > 3 && s <= 7) return defaults.EventDimensionStrokeEndColor;
    return defaults.EventDimensionStrokeColor;
  }

  function nodeEventDimensionSymbolFillConverter(s) {
    if (s <= 6) return '#fff';

    return '#fff';
  }

  //---------------------------------------------TEMPLATE------------------------------------------
  function getEventNode(scale, useAdornment, isForPalette?) {
    if (isForPalette) {
      console.warn(
        'using this function for palette icons i deprecated please use makePaletteNode function'
      );
    }

    return $GO(
      goService.Node,
      'Vertical',
      {
        locationObjectName: 'Icon',
        locationSpot: goService.Spot.Center,
        selectionAdorned: useAdornment
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify),
      // move a selected part into the Foreground layer, so it isn't obscured by any non-selected parts
      new goService.Binding('layerName', 'isSelected', function (s) {
        return s ? 'Foreground' : '';
      }).ofObject(),
      // can be resided according to the user's desires
      $GO(
        goService.Panel,
        'Auto',
        $GO(
          goService.Picture,
          {
            name: 'Icon',
            width: BPMN_CONSTANTS.diagram.icon.innerWidth,
            height: BPMN_CONSTANTS.diagram.icon.innerHeight,
            portId: '',
            fromLinkable: true,
            toLinkable: true,
            cursor: 'pointer',
            fromSpot: goService.Spot.RightSide,
            toSpot: goService.Spot.LeftSide
          },
          new goService.Binding('source', 'item', (item) => {
            switch (item.toLowerCase()) {
              case 'start':
                return ASSETS_PATH + '/start.svg';
              case 'end':
                return ASSETS_PATH + '/end.svg';
              default:
                throw new Error('Unsupported item type ' + item);
            }
          })
        ),
        $GO(goService.Shape, 'Square', {
          fill: 'transparent',
          stroke: null,
          width: BPMN_CONSTANTS.diagram.icon.innerWidth - 1,
          height: BPMN_CONSTANTS.diagram.icon.innerHeight - 1,
          name: 'outline',
          pickable: false
        }),
        $GO(goService.Shape, 'Circle', {
          fill: 'transparent',
          stroke: null,
          cursor: 'move',
          width: BPMN_CONSTANTS.diagram.icon.innerWidth - 10,
          height: BPMN_CONSTANTS.diagram.icon.innerHeight - 10
        })
      ),

      BpmnPartsFactory.getTextBlock(
        'text',
        undefined,
        BPMN_CONSTANTS.PaletteTextColour,
        'move',
        'normal 14px "Open Sans"'
      ),
      { click: BpmnCommonService.nodeClickHandler }
    ); // end goService.Node Vertical
  }
}

export type BpmnEventNodeServiceType = ReturnType<typeof bpmnEventNodeService>;
