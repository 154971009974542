'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';
import { ModelerValidation } from '@Shared.Angular/flowingly.modeler.validation/@types/services';

/**
 * @ngdoc service
 * @name BpmnComponentNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description  A service for creating BPMN Component nodes.
 *
 * ## Notes
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 *
 * ### Model Usage
 * { key: 5, category: "component", text: "Component", item: "generic task", taskType: 11}
 *
 * ###API
 * * getNode - Get a component node defined by the (optional) options
 * * getPaletteNode - Get a component palette node defined by the (optional) options
 */

class BpmnComponentNodeService {
  private defaults: any;
  private $GO: any;

  static $inject = [
    'goService',
    'BPMN_CONSTANTS',
    'BpmnPartsFactory',
    'BpmnCommonService',
    'modelerValidationErrorsService'
  ];

  constructor(
    private goService: GoJS,
    private BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
    private BpmnPartsFactory: BpmnModeler.BpmnParts,
    private BpmnCommonService: BpmnModeler.BpmnCommonService,
    private modelerValidationErrorsService: ModelerValidation.ModelerValidationErrorsService
  ) {
    this.$GO = this.goService.GraphObject.make;

    this.defaults = {
      ComponentWidth: 150,
      ComponentNodeStroke: this.BPMN_CONSTANTS.ActivityNodeBorder,
      ComponentNodeStrokeWidth: this.BPMN_CONSTANTS.ActivityNodeStrokeWidth,
      ComponentHeaderColor: this.BPMN_CONSTANTS.ActivityHeaderFillColor,

      ComponentHeaderHeight: 35,
      ComponentHeaderMargin: 35 / 4,
      ComponentHeaderFoldAmount: 35,

      MinComponentBodyHeight: 72,
      ComponentBodyHeight: 'auto'
    };

    this.goService.Shape.defineFigureGenerator(
      'ComponentHeader',
      (shape, w, h) => {
        return new this.goService.Geometry().add(
          new this.goService.PathFigure(0, h, true)
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                this.defaults.ComponentHeaderFoldAmount,
                0
              )
            )
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                w,
                0
              )
            )
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                w,
                h
              )
            )
        );
      }
    );

    this.goService.Shape.defineFigureGenerator(
      'ComponentFold',
      (shape, w, h) => {
        return new this.goService.Geometry().add(
          new this.goService.PathFigure(0, h, true)
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                this.defaults.ComponentHeaderFoldAmount,
                0
              )
            )
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                this.defaults.ComponentHeaderFoldAmount,
                h
              )
            )
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                0,
                h
              )
            )
        );
      }
    );

    this.goService.Shape.defineFigureGenerator(
      'ComponentBody',
      (shape, w, h) => {
        return new this.goService.Geometry().add(
          new this.goService.PathFigure(0, 0, true)
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                0,
                h + 1
              )
            ) //FLOW-5309: To compensate with the adjustment done in Ln. 108
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                w,
                h + 1
              )
            )
            .add(
              new this.goService.PathSegment(
                this.goService.PathSegment.Line,
                w,
                0
              )
            )
        );
      }
    );
  }

  private getNodeHeader() {
    return this.$GO(
      this.goService.Panel,
      'Auto',
      {},
      this.$GO(
        this.goService.Shape,
        'ComponentHeader',
        {
          fill: this.BPMN_CONSTANTS.Theme.FlowinglyLightGrey,
          width: this.defaults.ComponentWidth,
          height: this.defaults.ComponentHeaderHeight
        },
        // Business Rule for FLOW-4654, when valdiation the borders of
        // the nodes should turn red if they contain an error.
        new this.goService.Binding(
          'stroke',
          '',
          this.getBorderColor.bind(this)
        ),
        new this.goService.Binding(
          'strokeWidth',
          '',
          this.getBorderWidth.bind(this)
        )
      ),

      //header title
      this.$GO(
        this.goService.TextBlock,
        {
          alignment: new this.goService.Spot(
            0.5,
            0.5,
            this.defaults.ComponentHeaderFoldAmount / 2,
            0
          ),
          font: '600 10pt "Open Sans"',
          textAlign: 'center',
          stroke: '#408EE0',
          width:
            this.defaults.ComponentWidth -
            this.defaults.ComponentHeaderFoldAmount -
            this.defaults.ComponentHeaderMargin,
          height: this.defaults.MinComponentBodyHeight / 3,
          overflow: this.goService.TextBlock.OverflowEllipsis
        },
        new this.goService.Binding('text', 'actorName')
      )
    );
  }

  private getNodeBody() {
    return this.$GO(
      this.goService.Panel,
      'Auto',
      {
        alignment: new this.goService.Spot(0, 0, 0, -1) // to align it wth the header
      },
      this.$GO(
        this.goService.Shape,
        'ComponentBody',
        {
          fill: '#FFF',
          width: this.defaults.ComponentWidth,
          height: this.defaults.ComponentBodyHeight,
          fromLinkable: true,
          toLinkable: true,
          portId: 'activity',
          fromLinkableDuplicates: true,
          toLinkableDuplicates: true,
          toMaxLinks: 1,
          cursor: 'pointer',
          fromSpot: this.goService.Spot.RightSide,
          toSpot: this.goService.Spot.LeftSide,
          name: 'SQUARE'
        },
        // Business Rule for FLOW-4654, when valdiation the borders of
        // the nodes should turn red if they contain an error.
        new this.goService.Binding(
          'stroke',
          '',
          this.getBorderColor.bind(this)
        ),
        new this.goService.Binding(
          'strokeWidth',
          '',
          this.getBorderWidth.bind(this)
        )
      ),
      this.$GO(
        this.goService.Panel,
        'Vertical',
        {
          width: this.defaults.ComponentWidth
        },
        this.$GO(
          this.goService.TextBlock,
          {
            textAlign: 'center',
            font: '600 11pt "Open Sans"',
            stroke: '#35384D',
            editable: true,
            textEditor: this.BpmnCommonService.getTextEditingTool(),
            cursor: 'move',
            wrap: this.goService.TextBlock.WrapFit,
            isMultiline: true,
            stretch: this.goService.GraphObject.Horizontal,
            margin: new this.goService.Margin(12, 12),
            width: '300px'
          },
          new this.goService.Binding('text').makeTwoWay()
        ),
        this.getNodeSequenceBody()
      )
    );
  }

  private getNodeSequenceBody() {
    return this.$GO(
      this.goService.Panel,
      'Auto',
      {
        alignment: new this.goService.Spot(0, 1),
        margin: new this.goService.Margin(0, 0, 4, 4)
      },
      this.$GO(this.goService.Shape, 'Ellipse', {
        fill: this.defaults.ComponentHeaderColor,
        stroke: this.defaults.ComponentNodeStroke
      }),
      this.$GO(
        this.goService.TextBlock,
        {
          font: '600 10pt "Open Sans"',
          textAlign: 'center',
          stroke: '#35384D',
          margin: new this.goService.Margin(0, 5)
        },
        new this.goService.Binding('text', 'refSequence')
      ),
      new this.goService.Binding('opacity', '', (node) => {
        // use this instead of visiblity so that the entire
        // height will be calculated correctly
        if (node.refSequence && node.taskType !== 6) {
          return 1.0;
        } else {
          return 0.0;
        }
      })
    );
  }

  // @TODO flowingly.bpmn.activity.service has the
  // exact same function, we should merge this into
  // a service.
  private getBorderColor(goNodeModel) {
    const hasError = this.modelerValidationErrorsService.hasErrors(
      goNodeModel.key
    );
    return hasError
      ? this.BPMN_CONSTANTS.Theme.Error.Border.Color
      : this.defaults.ComponentNodeStroke;
  }

  // @TODO flowingly.bpmn.activity.service has the
  // exact same function, we should merge this into
  // a service.
  private getBorderWidth(goNodeModel) {
    const hasError = this.modelerValidationErrorsService.hasErrors(
      goNodeModel.key
    );
    return hasError
      ? this.BPMN_CONSTANTS.Theme.Error.Border.StrokeWidth / 2 // divde by 2 to match activity body
      : 1;
  }

  public getNode(options) {
    return this.$GO(
      this.goService.Node,
      'Auto',
      {
        isShadowed: true,
        shadowColor: this.BPMN_CONSTANTS.diagram.shadow.color,
        shadowBlur: this.BPMN_CONSTANTS.diagram.shadow.blur,
        shadowOffset: new this.goService.Point(
          this.BPMN_CONSTANTS.diagram.shadow.offsetX,
          this.BPMN_CONSTANTS.diagram.shadow.offsetY
        ),
        locationObjectName: 'Container',
        locationSpot: this.goService.Spot.Center,
        resizable: false,
        selectionAdorned: true,
        defaultStretch: this.goService.GraphObject.Vertical,
        cursor: 'move',
        fromSpot: this.goService.Spot.RightSide,
        toSpot: this.goService.Spot.LeftSide,
        fromMaxLinks: 1
      },

      new this.goService.Binding('locationObjectName', 'SQUARE'),
      new this.goService.Binding(
        'location',
        'loc',
        this.goService.Point.parse
      ).makeTwoWay(this.goService.Point.stringify),

      this.$GO(
        this.goService.Panel,
        'Vertical',
        { name: 'Container' },
        this.getNodeHeader(),
        this.getNodeBody()
      ),

      this.$GO(
        this.goService.Shape,
        'ComponentFold',
        {
          alignment: new this.goService.Spot(0, 0),
          fill: '#fdfcfc',
          width: this.defaults.ComponentWidth,
          height: this.defaults.ComponentHeaderHeight
        },
        // Business Rule for FLOW-4654, when valdiation the borders of
        // the nodes should turn red if they contain an error.
        new this.goService.Binding(
          'stroke',
          '',
          this.getBorderColor.bind(this)
        ),
        new this.goService.Binding(
          'strokeWidth',
          '',
          this.getBorderWidth.bind(this)
        )
      ),
      this.$GO(this.goService.Shape, {
        fill: 'transparent',
        stroke: null,
        cursor: 'move',
        width: this.defaults.ComponentWidth - 15,
        height: this.defaults.ComponentBodyHeight,
        alignment: this.goService.Spot.Left,
        name: 'Mask'
      }),
      { click: this.BpmnCommonService.nodeClickHandler }
    );
  }

  public getPaletteNode(options) {
    return this.$GO(
      this.goService.Part,
      this.goService.Panel.Vertical,
      {
        width: this.BPMN_CONSTANTS.palette.innerWidth,
        selectionAdorned: false
      },
      this.$GO(this.goService.Picture, {
        source: ASSETS_PATH + '/component.svg',
        width: !this.BpmnCommonService.isInternetExplorer
          ? this.BPMN_CONSTANTS.palette.icon.innerWidth
          : this.BPMN_CONSTANTS.palette.iconIE.component.innerWidth,
        height: !this.BpmnCommonService.isInternetExplorer
          ? this.BPMN_CONSTANTS.palette.icon.innerHeight
          : this.BPMN_CONSTANTS.palette.iconIE.component.innerWidth,
        scale: !this.BpmnCommonService.isInternetExplorer
          ? 1
          : this.BPMN_CONSTANTS.palette.iconIE.component.scale
      }),
      this.BpmnPartsFactory.getTextBlock(
        'text',
        undefined,
        this.BPMN_CONSTANTS.PaletteTextColour,
        'move',
        'normal 9px "Open Sans"'
      )
    );
  }
}

angular
  .module('flowingly.bpmn.modeler')
  .service('BpmnComponentNodeService', BpmnComponentNodeService);

export type BpmnComponentNodeServiceType = InstanceType<
  typeof BpmnComponentNodeService
>;
