/**
 * @ngdoc service
 * @name BpmnPoolNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description A service for creating BPMN Pool nodes.
 *
 * ## Notes
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 *
 * ### Model Usage
 *  { key: "501", "text": "Pool 1", "isGroup": "true", "category":"Pool" },
 *
 * ###API
 * * getNode - Get a pool node defined by the (optional) options
 * * getPaletteNode - Get a pool node palette node defined by the (optional) options
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/2a45f5a1603c5731704b21be8fc3199711034ac7/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.pool.service.js?at=master
 */

'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnPoolNodeService', BpmnPoolNodeService);

BpmnPoolNodeService.$inject = [
  'goService',
  'BpmnCommonService',
  'BPMN_CONSTANTS',
  '$window'
];

// ReSharper disable once InconsistentNaming
function BpmnPoolNodeService(
  goService: GoJS,
  BpmnCommonService: BpmnModeler.BpmnCommonService,
  BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
  $window: angular.IWindowService
) {
  const defaults = {
    GatewayNodeSize: BPMN_CONSTANTS.GatewayNodeSize,
    EventNodePaletteStrokeWidth: BPMN_CONSTANTS.EventNodePaletteStrokeWidth,
    GatewayNodeStroke: '#A3A3A3',
    FillColour: 'rgb(238,238,238)'
  };

  // swimlanes
  const MINLENGTH = 400; // this controls the minimum length of any swimlane
  const MINBREADTH = 20; // this controls the minimum breadth of any non-collapsed swimlane

  const service = {
    getNode: getNode,
    getPaletteNode: getPaletteNode
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  function getNode(options) {
    //angular.extend(defaults, options); //override defaults, if options supplied

    return getPoolNodeTemplate();
  }

  ///
  /// Get a Pool node defined by the (optional) options
  /// If options is not supplied, default values will be used
  ///
  function getPaletteNode(options) {
    //angular.extend(defaults, options); //override defaults, if options supplied

    return getPoolPaletteNode();
  }

  //private functions

  function groupStyle() {
    // common settings for both Lane and Pool Groups
    return [
      {
        layerName: 'Background', // all pools and lanes are always behind all nodes and links
        background: 'transparent', // can grab anywhere in bounds
        movable: true, // allows users to re-order by dragging
        copyable: false, // can't copy lanes or pools
        avoidable: false // don't impede AvoidsNodes routed Links
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify)
    ];
  }

  // define a custom grid layout that makes sure the length of each lane is the same
  // and that each lane is broad enough to hold its subgraph
  function poolLayout() {
    goService.GridLayout.call(this);
    this.cellSize = new goService.Size(1, 1);
    this.wrappingColumn = 1;
    this.wrappingWidth = Infinity;
    this.isRealtime = false; // don't continuously layout while dragging
    this.alignment = goService.GridLayout.Position;
    // This sorts based on the location of each Group.
    // This is useful when Groups can be moved up and down in order to change their order.
    this.comparer = function (a, b) {
      const ay = a.location.y;
      const by = b.location.y;
      if (isNaN(ay) || isNaN(by)) return 0;
      if (ay < by) return -1;
      if (ay > by) return 1;
      return 0;
    };
  }

  // compute the minimum size of a Pool Group needed to hold all of the Lane Groups
  function computeMinPoolSize(pool) {
    // assert(pool instanceof goService.Group && pool.category === "Pool");
    let len = MINLENGTH;
    pool.memberParts.each(function (lane) {
      // pools ought to only contain lanes, not plain Nodes
      if (!(lane instanceof goService.Group)) return;
      const holder = lane.placeholder;
      if (holder !== null) {
        const sz = holder.actualBounds;
        len = Math.max(len, sz.width);
      }
    });
    return new goService.Size(len, NaN);
  }

  // compute the minimum size for a particular Lane Group
  function computeLaneSize(lane) {
    // assert(lane instanceof goService.Group && lane.category !== "Pool");
    const sz = computeMinLaneSize(lane);
    if (lane.isSubGraphExpanded) {
      const holder = lane.placeholder;
      if (holder !== null) {
        const hsz = holder.actualBounds;
        sz.height = Math.max(sz.height, hsz.height);
      }
    }
    // minimum breadth needs to be big enough to hold the header
    const hdr = lane.findObject('HEADER');
    if (hdr !== null) sz.height = Math.max(sz.height, hdr.actualBounds.height);
    return sz;
  }

  // determine the minimum size of a Lane Group, even if collapsed
  function computeMinLaneSize(lane) {
    if (!lane.isSubGraphExpanded) return new goService.Size(MINLENGTH, 1);
    return new goService.Size(MINLENGTH, MINBREADTH);
  }

  function getPoolLayout() {
    goService.Diagram.inherit(poolLayout, goService.GridLayout);

    /** @override */
    poolLayout.prototype.doLayout = function (coll) {
      const diagram = $window.mainDiagram;
      if (diagram === null || diagram === undefined) return;
      diagram.startTransaction('poolLayout');
      const pool = this.group;
      if (pool !== null && pool.category === 'Pool') {
        // make sure all of the Group Shapes are big enough
        const minsize = computeMinPoolSize(pool);
        pool.memberParts.each(function (lane) {
          if (!(lane instanceof goService.Group)) return;
          if (lane.category !== 'Pool') {
            const shape = lane.resizeObject;
            if (shape !== null) {
              // change the desiredSize to be big enough in both directions
              const sz = computeLaneSize(lane);
              shape.width = isNaN(shape.width)
                ? minsize.width
                : Math.max(shape.width, minsize.width);
              shape.height = !isNaN(shape.height)
                ? Math.max(shape.height, sz.height)
                : sz.height;
              const cell = lane.resizeCellSize;
              if (!isNaN(shape.width) && !isNaN(cell.width) && cell.width > 0)
                shape.width = Math.ceil(shape.width / cell.width) * cell.width;
              if (
                !isNaN(shape.height) &&
                !isNaN(cell.height) &&
                cell.height > 0
              )
                shape.height =
                  Math.ceil(shape.height / cell.height) * cell.height;
            }
          }
        });
      }
      // now do all of the usual stuff, according to whatever properties have been set on this GridLayout
      goService.GridLayout.prototype.doLayout.call(this, coll);
      diagram.commitTransaction('poolLayout');
    };

    return new poolLayout();
  }

  function getPoolNodeTemplate() {
    const $GO = goService.GraphObject.make;

    const poolNodeTemplate = $GO(
      goService.Group,
      'Auto',
      groupStyle(),
      {
        // use a simple layout that ignores links to stack the "lane" Groups on top of each other
        // no space between lanes
        layout: $GO(getPoolLayout, { spacing: new goService.Size(0, 0) })
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify),
      { cursor: 'move' },
      $GO(
        goService.Shape,
        { fill: 'white', name: 'outline' },
        new goService.Binding('fill', 'color')
      ),
      $GO(
        goService.Panel,
        'Table',
        { defaultColumnSeparatorStroke: 'black' },
        $GO(
          goService.Panel,
          'Horizontal',
          { column: 0, angle: 270 },
          $GO(
            goService.TextBlock,
            {
              editable: true,
              textEditor: BpmnCommonService.getTextEditingTool(),
              margin: new goService.Margin(5, 0, 5, 0)
            }, // margin matches private process (black box pool)
            new goService.Binding('text').makeTwoWay(),
            new goService.Binding('font', 'fontSize', function (s) {
              return 'normal ' + s + 'px sans-serif';
            })
          )
        ),
        $GO(goService.Placeholder, { background: 'darkgray', column: 1 })
      ),
      { click: BpmnCommonService.nodeClickHandler }
    );
    return poolNodeTemplate;
  }

  function getPoolPaletteNode() {
    const $GO = goService.GraphObject.make;

    const poolNodePaletteTemplate = $GO(
      goService.Group,
      'Vertical',
      {
        isSubGraphExpanded: false,
        selectionAdorned: false
      },
      $GO(
        goService.Panel,
        'Vertical',
        {
          width: BPMN_CONSTANTS.palette.innerWidth,
          alignment: goService.Spot.Center
        },

        $GO(goService.Picture, {
          source: ASSETS_PATH + '/pool.svg',
          alignment: goService.Spot.Center,
          width: !BpmnCommonService.isInternetExplorer
            ? BPMN_CONSTANTS.palette.icon.innerWidth
            : BPMN_CONSTANTS.palette.iconIE.pool.innerWidth,
          height: !BpmnCommonService.isInternetExplorer
            ? BPMN_CONSTANTS.palette.icon.innerHeight
            : BPMN_CONSTANTS.palette.iconIE.pool.innerHeight,
          scale: !BpmnCommonService.isInternetExplorer
            ? 1
            : BPMN_CONSTANTS.palette.iconIE.pool.scale
        }),
        $GO(
          goService.TextBlock,
          {
            alignment: new goService.Spot(0.55, 1), // the center looks a bit off so we manually do this
            margin: 2,
            stroke: BPMN_CONSTANTS.PaletteTextColour,
            font: 'normal 11px "Open Sans"'
          },
          new goService.Binding('text')
        )
      )
    );

    return poolNodePaletteTemplate;
  }
}

export type BpmnPoolNodeServiceType = ReturnType<typeof BpmnPoolNodeService>;
